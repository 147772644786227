// src/styles/Hero.scss

.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    z-index: 1;
  
    &-title {
      font-size: 4rem;
      font-weight: bold;
      margin: 0;
  
      @media (max-width: 1024px) {
        font-size: 3rem;
      }
  
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
  
      @media (max-width: 480px) {
        font-size: 2rem;
      }
    }

    &-logo {
      max-width: 810px;
      height: auto;
      margin-bottom: 50px;

      @media screen and (max-width: 1599px) {
        max-width: 690px;
      }

      @media screen and (max-width: 1440px) {
        max-width: 640px;
      }

      @media screen and (max-width: 768px) {
        max-width: 410px;
      }

      @media screen and (max-width: 425px) {
        max-width: 290px;
      }
    }
  
    &-description {
      max-width: 642px;
      margin: 10px 0;
      color: #FFC774;
      font-size: 42px;
      text-transform: uppercase;
      margin-bottom: 50px;

      @media screen and (max-width: 1599px) {
        max-width: 400px;
        font-size: 34px;
      }

      @media screen and (max-width: 1440px) {
        max-width: 345px;
        font-size: 28px;
      }

      @media screen and (max-width: 768px) {
        max-width: 240px;
        font-size: 22px;
      }

      @media screen and (max-width: 425px) {
        max-width: 215px;
        font-size: 18px;
      }
  
    }

    &-available-on{
      width: 713px;
      color: #FFFFFF;
      border-bottom: 1px solid #FFC774;
      padding: 10px 0;

      @media screen and (max-width: 768px) {
        width: 450px;
      }

      @media screen and (max-width: 478px){
        width: 290px;
      }


      span{
        text-transform: uppercase;
        font-size: 32px;
        color: #FFFFFF;


        @media screen and (max-width: 1599px) {
          font-size: 24px;
        }

        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
 
      }
    }
  
    &-buttons {
      display: flex;
      gap: 15px;
      margin-top: 20px;

      @media screen and (max-width: 478px){
        flex-direction: column;
      }

    a, svg {
        display: block;
        width: 162px;
        height: 52px;
        margin: 0 12px;
        background-size: 100% 100%;
        transition: background-image 0.3s ease;
    }

    svg{
      cursor: not-allowed;
      rect{
        stroke: #6c6c6c;
      }
      path{
        fill: #6c6c6c;
      }
    }

    .google_play {
      background-image: url('../images/google.png');
    }

    .google_play:hover {
      background-image: url('../images/google_hovered.png');
    }
    }
  }
  