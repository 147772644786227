.privacy-policy-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    line-height: 1.8;
    color: #f0f0f0;
    background: linear-gradient(135deg, rgb(0, 0, 0), rgba(20, 20, 20, 0.85));
    box-shadow: 0px 4px 20px rgb(0, 0, 0);
    border-radius: 12px;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 999;

     /* Custom Scrollbar Styles */
     &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(50, 50, 50, 0.5); /* Dark, semi-transparent background */
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 204, 0, 0.8); /* Golden color to match theme */
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 204, 0, 1); /* Brighten on hover */
    }

    h2 {
      font-size: 2.2em;
      margin-bottom: 20px;
      color: #ffffff;
      text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
      font-weight: 700;
      text-align: center;
    }

    h3 {
      font-size: 1.7em;
      margin-top: 25px;
      color: #dcdcdc;
      font-weight: 600;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
    }

    p {
      font-size: 1.2em;
      margin-bottom: 18px;
      color: #e0e0e0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }

    ul {
      margin-left: 25px;
      list-style-type: disc;

      li {
        margin-bottom: 12px;
        font-size: 1.1em;
        color: #cfcfcf;
        padding-left: 5px;
        position: relative;

        &::marker {
          color: #ffcc00;
        }

        &:before {
          content: '•';
          color: #ffcc00;
          font-weight: bold;
          font-size: 1.2em;
          position: absolute;
          left: -20px;
          top: 3px;
        }
      }
    }

    // Responsive design for tablets
    @media (max-width: 768px) {
      padding: 20px;

      h2 {
        font-size: 2em;
      }

      h3 {
        font-size: 1.5em;
      }

      p,
      li {
        font-size: 1em;
      }
    }

    // Responsive design for mobile devices
    @media (max-width: 480px) {
      padding: 15px;

      h2 {
        font-size: 1.8em;
      }

      h3 {
        font-size: 1.3em;
      }

      p,
      li {
        font-size: 0.95em;
      }

      ul {
        margin-left: 20px;
      }
    }
  }
