.deletedata-page {
  height: 100%;

    &_block{
      max-width: 800px;
      height: 100%;
      margin: 0 auto;
      padding: 30px;
      line-height: 1.8;
      color: #f0f0f0;
      background: linear-gradient(135deg, rgb(0, 0, 0), rgba(20, 20, 20, 0.85));
      box-shadow: 0px 4px 20px rgb(0, 0, 0);
      border-radius: 12px;
      max-height: 300px;
      overflow-y: hidden;
      position: relative;
      z-index: 999;

      a{
        text-decoration: underline;
      }
    }


    h2 {
      font-size: 2.2em;
      margin-bottom: 20px;
      color: #ffffff;
      text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
      font-weight: 700;
      text-align: center;
    }

    p {
      font-size: 1.2em;
      margin-bottom: 18px;
      color: #e0e0e0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }

    ul {
      margin-left: 25px;
      list-style-type: disc;

      li {
        margin-bottom: 12px;
        font-size: 1.1em;
        color: #cfcfcf;
        padding-left: 5px;
        position: relative;

        &::marker {
          color: #ffcc00;
        }

        &:before {
          content: '•';
          color: #ffcc00;
          font-weight: bold;
          font-size: 1.2em;
          position: absolute;
          left: -20px;
          top: 3px;
        }
      }
    }

    // Responsive design for tablets
    @media (max-width: 768px) {
      padding: 20px;

      h2 {
        font-size: 2em;
      }

      p,
      li {
        font-size: 1em;
      }
    }

    // Responsive design for mobile devices
    @media (max-width: 480px) {
      padding: 15px;

      h2 {
        font-size: 1.8em;
      }

      p,
      li {
        font-size: 0.95em;
      }

      ul {
        margin-left: 20px;
      }
    }
  }
